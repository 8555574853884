import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import { selectPidsCards } from '../../../redux/slice/pidsCardSlice';
// import { selectPidsCards } from '../../../redux/slice/pidsCardSlice';
import Card from '../../card/Card';
import Loader from '../../loader/Loader';
import styles from './PidsCardAdd.module.scss';

const retailer = [
	{ id: 1, name: 'Autozone' },
	{ id: 2, name: 'OReilly' },
	{ id: 3, name: 'Advance' },
	{ id: 4, name: 'Napa' },
	{ id: 5, name: 'Amazon' },
	{ id: 6, name: 'Walmart' },
	{ id: 7, name: 'CarID' },
	{ id: 8, name: 'RockAuto' },
];
const cardType = [
	{ id: 1, type: 'BRAND' },
	{ id: 2, type: 'CATEGORY' },
	// { id: 3, type: 'SAMPLE' },
];

const initialState = {
	createdAt: '',
	editAt: '',
	editDate: '',
	ecommName: '', // Autozone, OReilly
	pidsCardEdition: '', // March 2023
	pidsCardVersion: '', // v01
	pidsCardLabel: '', // Spark Plug
	pidsCardID: '', //  zone-c--spark-plug
	pidsCardLogo:
		'https://firebasestorage.googleapis.com/v0/b/part-ids.appspot.com/o/pidsCards-logos%2FID.svg?alt=media&token=6c4e06f4-8877-4d6d-a170-1ade71ba81ef', // If brand 'NGK' use logo or category Spark Plug use Icon
	pidsCardType: 'CATEGORY', // BRAND or CATEGORY
	ecommCount: 0,
	pidsCardCount: 0,
	foundPercent: 0,
};

const PidsCardAdd = () => {
	const { id } = useParams();

	const pidsCards = useSelector(selectPidsCards);
	const pidsCardEdit = pidsCards.find((item) => item.id === id);
	// const pidsCards = useSelector(selectPidsCards);
	// const pidsCardEdit = pidsCards.find((item) => item.id === id);

	const [pidsCard, setPidsCard] = useState(() => {
		const newState = detectForm(id, { ...initialState }, pidsCardEdit);
		return newState;
	});
	// const [pidsCard, setPidsCard] = useState(() => {
	// 	const newState = detectForm(id, { ...initialState }, pidsCardEdit);
	// 	return newState;
	// });

	const [uploadProgress, setUploadProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	function detectForm(id, f1, f2) {
		if (id === 'ADD') {
			// console.log('F1');
			return f1;
		}
		// console.log('F2');
		return f2;
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setPidsCard({ ...pidsCard, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		// console.log(file);

		const storageRef = ref(storage, `pidsCards-logos/${file.name}`);

		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploadProgress(progress);
			},
			(error) => {
				toast.error(error.message);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setPidsCard({ ...pidsCard, pidsCardLogo: downloadURL });
					toast.success('Image uploaded successfully.');
				});
			}
		);
	};

	const addPidsCard = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		// const downloadRate = Number(0.009);
		// const priceAvailRate = Number(0.03);
		// const interchangeRate = Number(1.009);

		try {
			const uid = pidsCard.pidsCardID;
			await setDoc(doc(db, 'CARDS', uid), {
				createdAt: Timestamp.now().toDate(),
				editAt: Timestamp.now().toDate(),
				editDate: pidsCard.editDate,
				ecommName: pidsCard.ecommName,
				pidsCardEdition: pidsCard.pidsCardEdition,
				pidsCardVersion: pidsCard.pidsCardVersion,
				pidsCardLabel: pidsCard.pidsCardLabel,
				pidsCardID: pidsCard.pidsCardID,
				pidsCardLogo: pidsCard.pidsCardLogo,
				pidsCardType: pidsCard.pidsCardType,
				ecommCount: Number(pidsCard.ecommCount),
				pidsCardCount: Number(pidsCard.pidsCardCount),
				foundPercent: Number(pidsCard.pidsCardCount / pidsCard.ecommCount),
			});

			setIsLoading(false);
			setUploadProgress(0);
			setPidsCard({ ...initialState });

			toast.success('PidsCard uploaded successfully.');
			navigate('/pids/all-pids-cards');
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message);
		}
	};

	const editPidsCard = (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			setDoc(doc(db, 'CARDS', id), {
				createdAt: pidsCardEdit.createdAt,
				editAt: Timestamp.now().toDate(),
				editDate: pidsCard.editDate,
				ecommName: pidsCard.ecommName,
				pidsCardEdition: pidsCard.pidsCardEdition,
				pidsCardVersion: pidsCard.pidsCardVersion,
				pidsCardLabel: pidsCard.pidsCardLabel,
				pidsCardID: pidsCard.pidsCardID,
				pidsCardLogo: pidsCard.pidsCardLogo,
				pidsCardType: pidsCard.pidsCardType,
				ecommCount: Number(pidsCard.ecommCount),
				pidsCardCount: Number(pidsCard.pidsCardCount),
				foundPercent: Number(pidsCard.pidsCardCount / pidsCard.ecommCount),
			});

			setIsLoading(false);
			toast.success('PidsCard Edited Successfully');
			navigate('/jackjrs/all-pidsCards');
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.pidsCard}>
				<h2 style={{ color: 'orange' }}>
					{detectForm(id, 'Add PidsCard', 'Edit PidsCard')}
				</h2>

				<Card cardClass={styles.card}>
					<form onSubmit={detectForm(id, addPidsCard, editPidsCard)}>
						<label>Ecomm Name:</label>
						<select
							required
							name='ecommName'
							value={pidsCard.ecommName}
							onChange={(e) => handleInputChange(e)}
						>
							<option value='' disabled>
								-- choose Card channel --
							</option>
							{retailer.map((x) => {
								return (
									<option key={x.id} value={x.name}>
										{x.name}
									</option>
								);
							})}
						</select>

						<label>Date:</label>
						<input
							type='text'
							placeholder='2023-02-12 ...'
							// required
							name='editDate'
							value={pidsCard.editDate}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Edition:</label>
						<input
							type='text'
							placeholder='January 2023...'
							// required
							name='pidsCardEdition'
							value={pidsCard.pidsCardEdition}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Version:</label>
						<input
							type='text'
							placeholder='v01...'
							// required
							name='pidsCardVersion'
							value={pidsCard.pidsCardVersion}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Label:</label>
						<input
							type='text'
							placeholder='Spark Plug ...'
							// required
							name='pidsCardLabel'
							value={pidsCard.pidsCardLabel}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard ID:</label>
						<input
							type='text'
							placeholder='zone-c--spark-plug...'
							// required
							name='pidsCardID'
							value={pidsCard.pidsCardID}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Logo:</label>
						<Card cardClass={styles.group}>
							{uploadProgress === 0 ? null : (
								<div className={styles.progress}>
									<div
										className={styles['progress-bar']}
										style={{ width: `${uploadProgress}%` }}
									>
										{uploadProgress < 100
											? `Uploading ${uploadProgress}`
											: `Upload Complete ${uploadProgress}%`}
									</div>
								</div>
							)}

							<input
								type='file'
								accept='image/*'
								placeholder='pidsCardLogo...'
								name='image'
								onChange={(e) => handleImageChange(e)}
							/>

							{pidsCard.pidsCardLogo === '' ? null : (
								<input
									type='text'
									// required
									placeholder='pidsCardLogo'
									name='pidsCardLogo'
									value={pidsCard.pidsCardLogo}
									disabled
								/>
							)}
						</Card>

						<label>PidsCard Type:</label>
						<select
							// required
							name='pidsCardType'
							value={pidsCard.pidsCardType}
							onChange={(e) => handleInputChange(e)}
						>
							<option value='' disabled>
								-- choose pidsCard type --
							</option>
							{cardType.map((x) => {
								return (
									<option key={x.id} value={x.type}>
										{x.type}
									</option>
								);
							})}
						</select>

						<label>Ecomm Count:</label>
						<input
							type='number'
							placeholder='Display count ecommCount...'
							// required
							name='ecommCount'
							value={pidsCard.ecommCount}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Count:</label>
						<input
							type='number'
							placeholder='FOUND Card Count...'
							// required
							name='pidsCardCount'
							value={pidsCard.pidsCardCount}
							onChange={(e) => handleInputChange(e)}
						/>

						<button className='--btn --btn-primary'>
							{detectForm(id, 'Save PidsCard', 'Edit PidsCard')}
						</button>
					</form>
				</Card>
			</div>
		</>
	);
};

export default PidsCardAdd;
