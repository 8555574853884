import Navbar from '../../components/landing/Navbar';
import Hero from '../../components/landing/Hero';
import Stats from '../../components/landing/Stats';
import Business from '../../components/landing/Business';
import PartDeal from '../../components/landing/PartDeal';
import Testimonials from '../../components/landing/Testimonials';
import Clients from '../../components/landing/Clients';
import CTA from '../../components/landing/CTA';
import Footer from '../../components/landing/Footer';

import styles from './Landing.module.scss';

const Landing = () => {
	return (
		<>
			<section className={`container ${styles.landing}`}>
				<div className={`${styles.hero}`}>
					<Hero />
					{/* <Stats /> */}
				</div>

				<div className={styles.stats}>
					<div className={`${styles.boxWidth}`}>
						{/* <Business /> */}
						{/* <Billing /> */}
						{/* <PartDeal /> */}
						{/* <Testimonials /> */}
						{/* <Clients /> */}
						{/* <CTA /> */}
						{/* <Footer /> */}
					</div>
				</div>
			</section>
		</>
	);
};

export default Landing;
