import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	filteredPidsCards: [],
	filteredStacks: [],
};

const filterSlice = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		FILTER_BY_SEARCH(state, action) {
			const { pidsCards, search } = action.payload;
			const tempPidsCards = pidsCards.filter(
				(pidsCard) =>
					pidsCard.pidsCardLabel.toLowerCase().includes(search.toLowerCase())
				//  ||
				// pidsCard.pidsCardType.toLowerCase().includes(search.toLowerCase())
			);

			// state.filteredStacks = tempPidsCards;
			state.filteredPidsCards = tempPidsCards;
		},

		SORT_PIDSCARDS(state, action) {
			const { pidsCards, sort } = action.payload;
			let tempPidsCards = [];
			if (sort === 'latest') {
				tempPidsCards = pidsCards;
			}
			// if (sort === 'lowest-price') {
			// 	tempPidsCards = pidsCards.slice().sort((a, b) => {
			// 		return a.retailPrice - b.retailPrice;
			// 	});
			// }
			// if (sort === 'highest-price') {
			// 	tempPidsCards = pidsCards.slice().sort((a, b) => {
			// 		return b.retailPrice - a.retailPrice;
			// 	});
			// }
			if (sort === 'a-z') {
				tempPidsCards = pidsCards.slice().sort((a, b) => {
					return a.pidsCardLabel.localeCompare(b.pidsCardLabel);
				});
			}
			if (sort === 'z-a') {
				tempPidsCards = pidsCards.slice().sort((a, b) => {
					return b.pidsCardLabel.localeCompare(a.pidsCardLabel);
				});
			}
			state.filteredPidsCards = tempPidsCards;
		},
		// SORT_STACKS(state, action) {
		// 	const { pidsCards, sort } = action.payload;
		// 	let tempStacks = [];
		// 	if (sort === 'latest') {
		// 		tempStacks = pidsCards;
		// 	}
		// 	if (sort === 'lowest-price') {
		// 		tempStacks = pidsCards.slice().sort((a, b) => {
		// 			return a.retailPrice - b.retailPrice;
		// 		});
		// 	}
		// 	if (sort === 'highest-price') {
		// 		tempStacks = pidsCards.slice().sort((a, b) => {
		// 			return b.retailPrice - a.retailPrice;
		// 		});
		// 	}
		// 	if (sort === 'a-z') {
		// 		tempStacks = pidsCards.slice().sort((a, b) => {
		// 			return a.pidsCardLabel.localeCompare(b.pidsCardLabel);
		// 		});
		// 	}
		// 	if (sort === 'z-a') {
		// 		tempStacks = pidsCards.slice().sort((a, b) => {
		// 			return b.pidsCardLabel.localeCompare(a.pidsCardLabel);
		// 		});
		// 	}
		// 	state.filteredStacks = tempStacks;
		// },

		FILTER_BY_ECOMMNAME(state, action) {
			const { pidsCards, ecommName } = action.payload;
			let tempStacks = [];
			if (ecommName === 'All') {
				tempStacks = pidsCards;
			} else {
				tempStacks = pidsCards.filter(
					(pidsCard) => pidsCard.ecommName === ecommName
				);
			}
			state.filteredStacks = tempStacks;
		},

		FILTER_BY_PIDSCARDGROUP(state, action) {
			const { pidsCards, pidsCardGroup } = action.payload;
			let tempPidsCards = [];
			if (pidsCardGroup === 'All') {
				tempPidsCards = pidsCards;
			} else {
				tempPidsCards = pidsCards.filter(
					(pidsCard) => pidsCard.pidsCardGroup === pidsCardGroup
				);
			}
			state.filteredPidsCards = tempPidsCards;
		},
		// FILTER_BY_STACKGROUP(state, action) {
		// 	const { pidsCards, pidsCardGroup } = action.payload;
		// 	let tempStacks = [];
		// 	if (pidsCardGroup === 'All') {
		// 		tempStacks = pidsCards;
		// 	} else {
		// 		tempStacks = pidsCards.filter(
		// 			(pidsCard) => pidsCard.pidsCardGroup === pidsCardGroup
		// 		);
		// 	}
		// 	state.filteredStacks = tempStacks;
		// },

		FILTER_BY_PIDSCARDID(state, action) {
			const { pidsCards, pidsCardID } = action.payload;
			let tempPidsCards = [];
			if (pidsCardID === 'All') {
				tempPidsCards = pidsCards;
			} else {
				tempPidsCards = pidsCards.filter(
					(pidsCard) => pidsCard.pidsCardID === pidsCardID
				);
			}
			state.filteredPidsCards = tempPidsCards;
		},
		// FILTER_BY_STACKID(state, action) {
		// 	const { pidsCards, pidsCardID } = action.payload;
		// 	let tempStacks = [];
		// 	if (pidsCardID === 'All') {
		// 		tempStacks = pidsCards;
		// 	} else {
		// 		tempStacks = pidsCards.filter(
		// 			(pidsCard) => pidsCard.pidsCardID === pidsCardID
		// 		);
		// 	}
		// 	state.filteredStacks = tempStacks;
		// },

		FILTER_BY_PIDSCARDTYPE(state, action) {
			const { pidsCards, pidsCardType } = action.payload;
			let tempPidsCards = [];
			if (pidsCardType === 'All') {
				tempPidsCards = pidsCards;
			} else {
				tempPidsCards = pidsCards.filter(
					(pidsCard) => pidsCard.pidsCardType === pidsCardType
				);
			}
			state.filteredPidsCards = tempPidsCards;
		},
		// FILTER_BY_STACKTYPE(state, action) {
		// 	const { pidsCards, pidsCardType } = action.payload;
		// 	let tempStacks = [];
		// 	if (pidsCardType === 'All') {
		// 		tempStacks = pidsCards;
		// 	} else {
		// 		tempStacks = pidsCards.filter(
		// 			(pidsCard) => pidsCard.pidsCardType === pidsCardType
		// 		);
		// 	}
		// 	state.filteredStacks = tempStacks;
		// },

		FILTER_BY_PRICE(state, action) {
			const { pidsCards, price } = action.payload;
			let tempStacks = [];

			tempStacks = pidsCards.filter((pidsCard) => pidsCard.price <= price);

			state.filteredStacks = tempStacks;
		},
	},
});

export const {
	FILTER_BY_SEARCH,
	// SORT_STACKS,
	SORT_PIDSCARDS,
	FILTER_BY_ECOMMNAME,
	FILTER_BY_PIDSCARDID,
	// FILTER_BY_STACKID,
	FILTER_BY_PIDSCARDTYPE,
	// FILTER_BY_STACKTYPE,
	FILTER_BY_PIDSCARDGROUP,
	// FILTER_BY_STACKGROUP,
	FILTER_BY_PRICE,
} = filterSlice.actions;

// export const selectFilteredStacks = (state) => state.filter.filteredStacks;
export const selectFilteredPidsCards = (state) =>
	state.filter.filteredPidsCards;

export default filterSlice.reducer;
