import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	// stacks: [],
	pidsCards: [],
};

const pidsCardSlice = createSlice({
	name: 'pidsCard',
	initialState,
	reducers: {
		STORE_PIDSCARDS(state, action) {
			state.pidsCards = action.payload.pidsCards;
		},
		GET_PROJECT_LIST(state, action) {
			const { pidsCards } = action.payload;
		},
	},
});

export const { STORE_PIDSCARDS } = pidsCardSlice.actions;
// export const { STORE_STACKS } = stackSlice.actions;

export const selectPidsCards = (state) => state.pidsCard.pidsCards;
// export const selectStacks = (state) => state.stack.stacks;

export default pidsCardSlice.reducer;
// export default stackSlice.reducer;
