import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// import {
// 	ADD_TO_CART,
// 	CALCULATE_TOTAL_QUANTITY,
// } from '../../../redux/slice/cartSlice';
import Card from '../../card/Card';
import styles from './ProductItem.module.scss';

const ProductItem = ({
	product,
	grid,
	id,
	pidsCardCount,
	ecommName,
	pidsCardLabel,
	pidsCardLogo,
}) => {
	const dispatch = useDispatch();
	const shortenText = (text, n) => {
		if (text.length > n) {
			const shortenedText = text.substring(0, n).concat('...');
			return shortenedText;
		}
		return text;
	};

	// const addToCart = (product) => {
	// 	dispatch(ADD_TO_CART(product));
	// 	dispatch(CALCULATE_TOTAL_QUANTITY());
	// };

	return (
		<Card cardClass={grid ? `${styles.grid}` : `${styles.list}`}>
			<Link to={`/product-details/${id}`}>
				<div className={styles.img}>
					<img src={pidsCardLogo} alt={pidsCardLabel} />
				</div>
			</Link>
			<div className={styles.content}>
				<div className={styles.details}>
					<p>{`${pidsCardCount}`}</p>
					<h3>{shortenText(ecommName, 18)}</h3>
					<h4>{shortenText(pidsCardLabel, 18)}</h4>
					<h5>{shortenText(pidsCardLabel, 18)}</h5>
				</div>
				{!grid && (
					<p className={styles.desc}>{shortenText(pidsCardLabel, 200)}</p>
				)}

				<button
					className='--btn --btn-danger'
					// onClick={() => addToCart(product)}
				>
					DETAILS
				</button>
			</div>
		</Card>
	);
};

export default ProductItem;
