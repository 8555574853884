import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddProduct from '../../components/adminPids/addProduct/AddProduct';
import Navbar from '../../components/adminPids/navbar/Navbar';
// import OrderDetailsPidsCredits from '../../components/adminPids/orderDetailsPidsCredits/OrderDetailsPidsCredits';
// import OrdersPidsCredits from '../../components/adminPids/ordersPidsCredits/OrdersPidsCredits';
import PidsCardAdd from '../../components/adminPids/pidsCardAdd/PidsCardAdd';
import PidsCardView from '../../components/adminPids/pidsCardView/PidsCardView';
import PidsDash from '../../components/adminPids/pidsDash/PidsDash';
import ViewProducts from '../../components/adminPids/viewProducts/ViewProducts';
import styles from './AdminPids.module.scss';

const AdminPids = () => {
	return (
		<div className={styles.adminPids}>
			<div className={styles.navbar}>
				<Navbar />
			</div>
			<div className={styles.content}>
				<Routes>
					<Route path='dash' element={<PidsDash />} />
					<Route path='add-pids-card/:id' element={<PidsCardAdd />} />
					<Route path='all-pids-cards' element={<PidsCardView />} />
					<Route path='all-products' element={<ViewProducts />} />
					<Route path='add-product/:id' element={<AddProduct />} />
				</Routes>
			</div>
		</div>
	);
};

export default AdminPids;
