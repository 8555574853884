import styles from './Landing.module.scss';
import { arrowUp } from '../../assets';

const GetStarted = () => {
  return (
    // <div className={`${styles.getstarted} w-[140px] h-[140px] rounded-full bg-blue-gradient p-[2px] cursor-pointer`}>
    <div className={styles.getstarted}>
      <div >
        <div>
          <h3 style={{color: 'white'}}>
            <span>EASY</span>
          </h3>
            <img src={arrowUp} alt='arrow' className='w-[23px] h-[23px] object-contain' />
        </div>
        <p className='font-poppins font-medium text-[18px] leading-[23px]'>
            <span className='text-gradient'>Get Started</span>
          </p>
      </div>
    </div>
  )
}

export default GetStarted