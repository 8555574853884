import { collection, deleteDoc, doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, storage } from '../../firebase/config';
import Loader from '../../components/loader/Loader';
import styles from './Library.module.scss';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { deleteObject, ref } from 'firebase/storage';
import Notiflix from 'notiflix';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectPidsCards,
	STORE_PIDSCARDS,
} from '../../redux/slice/pidsCardSlice';

import useFetchCollection from '../../customHooks/useFetchCollection';
import {
	FILTER_BY_SEARCH,
	selectFilteredPidsCards,
} from '../../redux/slice/filterSlice2';
import Pagination from '../../components/pagination/Pagination';

const Library = () => {
	const { data, isLoading } = useFetchCollection('CARDS');
	// const { data1, isLoading1 } = useFetchCollectionSub('collect2');
	const pidsCards = useSelector(selectPidsCards);
	const filteredPidsCards = useSelector(selectFilteredPidsCards);

	// console.log(data1.length);

	// Pagination states
	const [currentPage, setCurrentPage] = useState(1);
	const [pidsCardsPerPage, setPidsCardsPerPage] = useState(10);

	// Get Current PidsCards
	const indexOfLastPidsCard = currentPage * pidsCardsPerPage;
	const indexOfFirstPidsCard = indexOfLastPidsCard - pidsCardsPerPage;
	const currentPidsCards = filteredPidsCards.slice(
		indexOfFirstPidsCard,
		indexOfLastPidsCard
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			STORE_PIDSCARDS({
				pidsCards: data,
			})
		);
	}, [dispatch, data]);

	const confirmDelete = (id) => {
		Notiflix.Confirm.show(
			'Delete PidsCard!!!',
			'You are about to delete this PidsCard from the database',
			'Delete',
			'Cancel',
			function okCb() {
				deletePidsCard(id);
			},
			function cancelCb() {
				console.log('Delete Canceled');
			},
			{
				width: '320px',
				borderRadius: '3px',
				titleColor: 'orangered',
				okButtonBackground: 'orangered',
				cssAnimationStyle: 'zoom',
			}
		);
	};

	const deletePidsCard = async (id) => {
		try {
			await deleteDoc(doc(db, 'PidsCards--Autoparts', id));

			toast.success('PidsCard deleted successfully.');
		} catch (error) {
			toast.error(error.message);
		}
	};
	console.log(pidsCards);

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.table}>
				<h2>All PidsCards</h2>

				<div className={styles.search}>
					<p>
						Found <b>{pidsCards.length}</b> PidsCards
					</p>
				</div>

				<div className={styles.search}></div>

				{pidsCards.length === 0 ? (
					<p>No pidsCard found.</p>
				) : (
					<table>
						<thead>
							<tr>
								<th>Ecomm</th>
								<th>Logo</th>
								<th>ID Count</th>
								<th>Download $</th>
								<th>PidsCard Type</th>
								<th>PidsCard Label</th>
								<th>PidsCard ID</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{pidsCards.map((pidsCard, index) => {
								const {
									id,
									ecommName,
									pidsCardLogo,
									pidsCardCount,
									downloadPrice,
									pidsCardType,
									pidsCardLabel,
									pidsCardID,
								} = pidsCard;
								return (
									<tr key={id}>
										<td>{ecommName}</td>
										<td>
											<Link to={`/jackjrs/all-partids/${pidsCardID}`}>
												<img src={pidsCardLogo} alt={pidsCardID} />
											</Link>
										</td>
										<td>{pidsCardCount}</td>
										<td>$ {Number(`${downloadPrice}`).toFixed(2)}</td>
										<td>{pidsCardType}</td>
										<td>{pidsCardLabel}</td>
										<td>{pidsCardID}</td>
										<td className={styles.icons}>
											<Link to={`/jackjrs/add-pidsCard/${id}`}>
												<FaEdit size={20} color='green' />
											</Link>
											&nbsp;
											<FaTrashAlt
												size={18}
												color='red'
												onClick={() => confirmDelete(id)}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
				<Pagination
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					pidsCardsPerPage={pidsCardsPerPage}
					totalPidsCards={filteredPidsCards.length}
				/>
			</div>
		</>
	);
};

export default Library;
