import { initializeApp, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyCqA3c2f7HdLI8pSoP_Q8o0HzWuIkUDFsU',
	authDomain: 'part-ids.firebaseapp.com',
	projectId: 'part-ids',
	storageBucket: 'part-ids.appspot.com',
	messagingSenderId: '503953910558',
	appId: '1:503953910558:web:265fd9f8f40cbc7ca1370f',
	measurementId: 'G-H6297MDTEY',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
