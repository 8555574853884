import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import Card from '../../card/Card';
import Loader from '../../loader/Loader';
import styles from './AddProduct.module.scss';
import { selectProducts } from '../../../redux/slice/productSlice';

const retailer = [
	{ id: 1, name: 'Autozone' },
	{ id: 2, name: 'OReilly' },
	{ id: 3, name: 'Advance' },
	{ id: 4, name: 'Napa' },
	{ id: 5, name: 'Amazon' },
	{ id: 6, name: 'Walmart' },
	{ id: 7, name: 'CarID' },
	{ id: 8, name: 'RockAuto' },
];
const cardType = [
	{ id: 1, type: 'BRAND' },
	{ id: 2, type: 'CATEGORY' },
	// { id: 3, type: 'SAMPLE' },
];

const initialState = {
	createdAt: '',
	editAt: '',
	editDate: '',
	ecommName: '', // Autozone, OReilly
	pidsCardEdition: '', // March 2023
	pidsCardVersion: 'v01', // v01
	pidsCardLabel: '', // Spark Plug
	pidsCardID: '', //  zone-c--spark-plug
	pidsCardLogo:
		'https://firebasestorage.googleapis.com/v0/b/part-ids.appspot.com/o/pidsCards-logos%2FID.svg?alt=media&token=6c4e06f4-8877-4d6d-a170-1ade71ba81ef', // If brand 'NGK' use logo or category Spark Plug use Icon
	pidsCardType: 'CATEGORY', // BRAND or CATEGORY
	ecommCount: 0,
	pidsCardCount: 0,
	foundPercent: 0,
};

const AddProduct = () => {
	const { id } = useParams();
	const products = useSelector(selectProducts);
	const productEdit = products.find((item) => item.id === id);
	console.log(productEdit);

	const [product, setProduct] = useState(() => {
		const newState = detectForm(id, { ...initialState }, productEdit);
		return newState;
	});

	const [uploadProgress, setUploadProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	function detectForm(id, f1, f2) {
		if (id === 'ADD') {
			return f1;
		}
		return f2;
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setProduct({ ...product, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		// console.log(file);

		const storageRef = ref(storage, `pids/${file.name}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploadProgress(progress);
			},
			(error) => {
				toast.error(error.message);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setProduct({ ...product, imageURL: downloadURL });
					toast.success('Image uploaded successfully.');
				});
			}
		);
	};

	const addProduct = async (e) => {
		e.preventDefault();
		// console.log(product);
		setIsLoading(true);

		try {
			await setDoc(doc(db, 'CARDS', id), {
				createdAt: Timestamp.now().toDate(),
				editAt: Timestamp.now().toDate(),
				editDate: product.editDate,
				ecommName: product.ecommName,
				pidsCardEdition: product.pidsCardEdition,
				pidsCardVersion: product.pidsCardVersion,
				pidsCardLabel: product.pidsCardLabel,
				pidsCardID: product.pidsCardID,
				pidsCardLogo: product.pidsCardLogo,
				pidsCardType: product.pidsCardType,
				ecommCount: Number(product.ecommCount),
				pidsCardCount: Number(product.pidsCardCount),
				foundPercent: Number(product.pidsCardCount / product.ecommCount),
			});

			// try {
			// 	const docRef = addDoc(collection(db, 'products'), {
			// 		name: product.name,
			// 		imageURL: product.imageURL,
			// 		price: Number(product.price),
			// 		category: product.category,
			// 		brand: product.brand,
			// 		desc: product.desc,
			// 		createdAt: Timestamp.now().toDate(),
			// 	});
			setIsLoading(false);
			setUploadProgress(0);
			setProduct({ ...initialState });

			toast.success('Product uploaded successfully.');
			navigate('/pids/all-products');
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message);
		}
	};

	const editProduct = (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (product.imageURL !== productEdit.imageURL) {
			const storageRef = ref(storage, productEdit.imageURL);
			deleteObject(storageRef);
		}

		try {
			setDoc(doc(db, 'CARDS', id), {
				createdAt: product.createdAt,
				editAt: Timestamp.now().toDate(),
				editDate: product.editDate,
				ecommName: product.ecommName,
				pidsCardEdition: product.pidsCardEdition,
				pidsCardVersion: product.pidsCardVersion,
				pidsCardLabel: product.pidsCardLabel,
				pidsCardID: product.pidsCardID,
				pidsCardLogo: product.pidsCardLogo,
				pidsCardType: product.pidsCardType,
				ecommCount: Number(product.ecommCount),
				pidsCardCount: Number(product.pidsCardCount),
				foundPercent: Number(product.pidsCardCount / product.ecommCount),
			});
			setIsLoading(false);
			toast.success('Product Edited Successfully');
			navigate('/pids/all-products');
		} catch (error) {
			setIsLoading(false);
			toast.error(error.message);
		}
	};

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.product}>
				<h2>{detectForm(id, 'Add New Product', 'Edit Product')}</h2>
				<Card cardClass={styles.card}>
					<form onSubmit={detectForm(id, addProduct, editProduct)}>
						<label>Ecomm Name:</label>
						<select
							required
							name='ecommName'
							value={product.ecommName}
							onChange={(e) => handleInputChange(e)}
						>
							<option value='' disabled>
								-- choose channel --
							</option>
							{retailer.map((x) => {
								return (
									<option key={x.id} value={x.name}>
										{x.name}
									</option>
								);
							})}
						</select>

						<label>Date:</label>
						<input
							type='text'
							placeholder='2023-02-12 ...'
							// required
							name='editDate'
							value={product.editDate}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Edition:</label>
						<input
							type='text'
							placeholder='January 2023...'
							// required
							name='pidsCardEdition'
							value={product.pidsCardEdition}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Version:</label>
						<input
							type='text'
							placeholder='v01...'
							// required
							name='pidsCardVersion'
							value={product.pidsCardVersion}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Label:</label>
						<input
							type='text'
							placeholder='Spark Plug ...'
							// required
							name='pidsCardLabel'
							value={product.pidsCardLabel}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard ID:</label>
						<input
							type='text'
							placeholder='zone-c--spark-plug...'
							// required
							name='pidsCardID'
							value={product.pidsCardID}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Logo:</label>
						<Card cardClass={styles.group}>
							{uploadProgress === 0 ? null : (
								<div className={styles.progress}>
									<div
										className={styles['progress-bar']}
										style={{ width: `${uploadProgress}%` }}
									>
										{uploadProgress < 100
											? `Uploading ${uploadProgress}`
											: `Upload Complete ${uploadProgress}%`}
									</div>
								</div>
							)}

							<input
								type='file'
								accept='image/*'
								placeholder='pidsCardLogo...'
								name='image'
								onChange={(e) => handleImageChange(e)}
							/>

							{product.pidsCardLogo === '' ? null : (
								<input
									type='text'
									// required
									placeholder='pidsCardLogo'
									name='pidsCardLogo'
									value={product.pidsCardLogo}
									disabled
								/>
							)}
						</Card>

						<label>Card Type:</label>
						<select
							// required
							name='pidsCardType'
							value={product.pidsCardType}
							onChange={(e) => handleInputChange(e)}
						>
							<option value='' disabled>
								-- choose pidsCard type --
							</option>
							{cardType.map((x) => {
								return (
									<option key={x.id} value={x.type}>
										{x.type}
									</option>
								);
							})}
						</select>

						<label>Ecomm Count:</label>
						<input
							type='number'
							placeholder='Display count ecommCount...'
							// required
							name='ecommCount'
							value={product.ecommCount}
							onChange={(e) => handleInputChange(e)}
						/>

						<label>PidsCard Count:</label>
						<input
							type='number'
							placeholder='FOUND Card Count...'
							// required
							name='pidsCardCount'
							value={product.pidsCardCount}
							onChange={(e) => handleInputChange(e)}
						/>

						<button className='--btn --btn-primary'>
							{detectForm(id, 'Save PidsCard', 'Edit PidsCard')}
						</button>
					</form>
				</Card>
			</div>
		</>
	);
};

export default AddProduct;
