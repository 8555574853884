import { deleteDoc, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import styles from './ViewProducts.module.scss';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import Loader from '../../loader/Loader';
import { deleteObject, ref } from 'firebase/storage';
import Notiflix from 'notiflix';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectProducts,
	STORE_PRODUCTS,
} from '../../../redux/slice/productSlice';
import useFetchCollection from '../../../customHooks/useFetchCollection';
import {
	FILTER_BY_SEARCH,
	selectFilteredProducts,
} from '../../../redux/slice/filterSlice';
import Search from '../../search/Search';
import Pagination from '../../pagination/Pagination';

const ViewProducts = () => {
	const [search, setSearch] = useState('');
	const { data, isLoading } = useFetchCollection('CARDS');
	const products = useSelector(selectProducts);
	const filteredProducts = useSelector(selectFilteredProducts);
	// Pagination states
	const [currentPage, setCurrentPage] = useState(1);
	const [productsPerPage, setProductsPerPage] = useState(10);
	// Get Current Products
	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = filteredProducts.slice(
		indexOfFirstProduct,
		indexOfLastProduct
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			STORE_PRODUCTS({
				products: data,
			})
		);
	}, [dispatch, data]);

	useEffect(() => {
		dispatch(FILTER_BY_SEARCH({ products, search }));
	}, [dispatch, products, search]);

	const confirmDelete = (id, imageURL) => {
		Notiflix.Confirm.show(
			'Delete Product!!!',
			'You are about to delete this product',
			'Delete',
			'Cancel',
			function okCb() {
				deleteProduct(id, imageURL);
			},
			function cancelCb() {
				console.log('Delete Canceled');
			},
			{
				width: '320px',
				borderRadius: '3px',
				titleColor: 'orangered',
				okButtonBackground: 'orangered',
				cssAnimationStyle: 'zoom',
			}
		);
	};

	const deleteProduct = async (id, imageURL) => {
		try {
			await deleteDoc(doc(db, 'products', id));

			const storageRef = ref(storage, imageURL);
			await deleteObject(storageRef);
			toast.success('Product deleted successfully.');
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.table}>
				<h2>All Products</h2>

				<div className={styles.search}>
					<p>
						<b>{filteredProducts.length}</b> products found
					</p>
					<Search value={search} onChange={(e) => setSearch(e.target.value)} />
				</div>

				{filteredProducts.length === 0 ? (
					<p>No product found.</p>
				) : (
					<table>
						<thead>
							<tr>
								<th>s/n</th>
								<th>Label</th>
								<th>Ecomm</th>
								<th>Edition</th>
								<th>Version</th>
								<th>Ecomm Count</th>
								<th>Card Count</th>
								<th>Type</th>
								<th>Image</th>
								<th>update</th>
								<th>% Found</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{currentProducts.map((product, index) => {
								const {
									id,
									pidsCardID,
									pidsCardLabel,
									ecommName,
									pidsCardEdition,
									pidsCardVersion,
									ecommCount,
									pidsCardCount,
									pidsCardType,
									pidsCardLogo,
									editDate,
									foundPercent,
								} = product;
								return (
									<tr key={id}>
										<td>{pidsCardID}</td>
										<td>{pidsCardLabel}</td>
										<td>{ecommName}</td>
										<td>{pidsCardEdition}</td>
										<td>{pidsCardVersion}</td>
										<td>{ecommCount}</td>
										<td>{pidsCardCount}</td>
										<td>{pidsCardType}</td>
										<td>
											<img
												src={pidsCardLogo}
												alt={pidsCardLabel}
												style={{ width: '100px' }}
											/>
										</td>
										<td>{editDate}</td>
										<td>{Number(foundPercent).toFixed(2)}</td>

										<td className={styles.icons}>
											<Link to={`/admin/add-product/${id}`}>
												<FaEdit size={20} color='green' />
											</Link>
											&nbsp;
											<FaTrashAlt
												size={18}
												color='red'
												onClick={() => confirmDelete(id, pidsCardLogo)}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
				<Pagination
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					productsPerPage={productsPerPage}
					totalProducts={filteredProducts.length}
				/>
			</div>
		</>
	);
};

export default ViewProducts;
