import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	// Home,
	Contact,
	CardHome,
	Login,
	Register,
	Reset,
	// Jackjrs,
	// Console,
	// ProjectRequest,
	// ProjectRequestDetails,
	// ProjectRequestSuccess,
	// ProjectOrderDetails,
	// ProjectOrderHistory,
	// UserStack,
	// UserProfile,
	// ReviewProducts,
	// Projects,
	// PartID,
	NotFound,
	Landing,
	AdminPids,
	Library,
} from './pages';

import {
	AdminPidsOnlyRoute,
	Footer,
	Header,
	// JackjrsOnlyRoute,
	// StackDetails,
} from './components';
// import SampleData from './components/sampleData/SampleData';
// import SamplePricing from './components/samplePricing/SamplePricing';
// import SampleInterchange from './components/sampleInterchange/SampleInterchange';
// import StackSamples from './pages/stackSamples/StackSamples';
// import UserCollections from './pages/userCollections/UserCollections';
// import PartIDCardDetails from './components/partIDCard/partIDCardDetails/PartIDCardDetails';

function App() {
	const user = 'null';

	return (
		<div className='app'>
			<BrowserRouter>
				<ToastContainer />
				{!user ? (
					<Login />
				) : (
					<>
						<Header />
						<Routes>
							<Route path='/' element={<Landing />} />
							<Route path='/cards' element={<CardHome />} />
							<Route path='/library' element={<Library />} />
							{/* <Route path='/stack-samples' element={<StackSamples />} /> */}
							{/* <Route path='/console' element={<Console />} /> */}
							<Route path='/contact' element={<Contact />} />
							<Route path='/login' element={<Login />} />
							<Route path='/register' element={<Register />} />
							<Route path='/reset' element={<Reset />} />
							{/* <Route path='/profile' element={<UserProfile />} /> */}

							{/* <Route
								path='/jackjrs/*'
								element={
									<JackjrsOnlyRoute>
										<Jackjrs />
									</JackjrsOnlyRoute>
								}
							/> */}
							<Route
								path='/pids/*'
								element={
									<AdminPidsOnlyRoute>
										<AdminPids />
									</AdminPidsOnlyRoute>
								}
							/>
							<Route path='*' element={<NotFound />} />
						</Routes>
						<Footer />
					</>
				)}
			</BrowserRouter>
		</div>
	);
}

export default App;
