import React from 'react';
import styles from './NotFound.module.scss';

const NotFound = () => {
	return (
		<div>
			<h1>Not Found</h1>
		</div>
	);
};

export default NotFound;
