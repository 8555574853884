import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';

import partidsLogo from '../../assets/partIDslogo-3.svg';
import { FaTimes } from 'react-icons/fa';
import { MdLibraryBooks } from 'react-icons/md';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';

import { auth } from '../../firebase/config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	REMOVE_ACTIVE_USER,
	SET_ACTIVE_USER,
} from '../../redux/slice/authSlice';

import ShowOnLogin, { ShowOnLogout } from '../hiddenLink/HiddenLink';
import { AdminPidsOnlyLink } from '../adminPidsOnlyRoute/AdminPidsOnlyRoute';

const logo = (
	<div className={styles.logo}>
		<Link to='/cards'>
			<img src={partidsLogo} alt='partidsLogo' style={{ width: '180px' }} />
		</Link>
	</div>
);
const activeLink = ({ isActive }) => (isActive ? `${styles.active2}` : '');

const Header = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [displayName, setdisplayName] = useState('');
	const [scrollPage, setScrollPage] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const fixNavbar = () => {
		if (window.scrollY > 50) {
			setScrollPage(true);
		} else {
			setScrollPage(false);
		}
	};
	window.addEventListener('scroll', fixNavbar);

	// Monitor currently sign in user
	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// console.log(user);
				if (user.displayName == null) {
					const u1 = user.email.slice(0, -10);
					const uName = u1.charAt(0).toUpperCase() + u1.slice(1);
					setdisplayName(uName);
				} else {
					setdisplayName(user.displayName);
				}

				dispatch(
					SET_ACTIVE_USER({
						email: user.email,
						userName: user.displayName ? user.displayName : displayName,
						userID: user.uid,
					})
				);
			} else {
				setdisplayName('');
				dispatch(REMOVE_ACTIVE_USER());
			}
		});
	}, [dispatch, displayName]);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const hideMenu = () => {
		setShowMenu(false);
	};

	const logoutUser = () => {
		signOut(auth)
			.then(() => {
				toast.success('Logout successfully.');
				navigate('/');
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};

	const projects = (
		<span className={styles.projects}>
			<Link to='/projects'>
				<MdLibraryBooks size={20} />
				Requests
				<p>{0}</p>
			</Link>
		</span>
	);

	return (
		<>
			<header className={scrollPage ? `${styles.fixed}` : null}>
				<div className={styles.header}>
					{logo}

					<nav
						className={
							showMenu ? `${styles['show-nav']}` : `${styles['hide-nav']}`
						}
					>
						<div
							className={
								showMenu
									? `${styles['nav-wrapper']} ${styles['show-nav-wrapper']}`
									: `${styles['nav-wrapper']}`
							}
							onClick={hideMenu}
						></div>

						<ul onClick={hideMenu}>
							<li className={styles['logo-mobile']}>
								<img
									src={partidsLogo}
									alt='partidsLogo'
									style={{ width: '150px' }}
								/>

								<FaTimes size={22} color='#fff' onClick={hideMenu} />
							</li>

							<li>
								<AdminPidsOnlyLink>
									<Link to='/pids/'>
										<button className='--btn --btn-danger'>PIDS ADMIN</button>
									</Link>
								</AdminPidsOnlyLink>
							</li>
						</ul>
						<div className={styles['header-right']} onClick={hideMenu}>
							<span className={styles.links}>
								<ShowOnLogout>
									<NavLink to='/login' className={activeLink}>
										Login
									</NavLink>
								</ShowOnLogout>
								<ShowOnLogin>
									<NavLink to='/cards'>
										<BsFillGrid3X3GapFill
											size={22}
											color='orange'
											// onClick={() => setGrid(true)}
										/>
									</NavLink>

									<NavLink
										to='/project-order-history'
										className={activeLink}
										style={{ color: 'orange' }}
									>
										{/* <GiEarthAmerica
											style={{ color: 'deepskyblue' }}
											size={19}
										/> */}

										{displayName}
									</NavLink>

									<a
										href='/project-order-history'
										style={{ color: '#ff7722' }}
									></a>
								</ShowOnLogin>
								<ShowOnLogin>
									<NavLink to='/contact' className={activeLink}>
										Contact Us
									</NavLink>
									{/* <NavLink to='/project-order-history' className={activeLink}>
										Orders
									</NavLink> */}
									<NavLink to='/login' onClick={logoutUser}>
										Logout
									</NavLink>
								</ShowOnLogin>
							</span>
							{/* {projects} */}
							{projects}
						</div>
					</nav>

					<div className={styles['menu-icon']}>
						{projects}

						<HiOutlineMenuAlt3 size={28} onClick={toggleMenu} />
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
