import styles from './Landing.module.scss';
import { discount, robot } from "../../assets";
import GetStarted from "./GetStarted";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section id="home" className={styles.landheader}>
      <div >
        <div className={styles.hero}>
          <h1>
            <span>Part IDs Membership - Aftermarket Auto Parts Edition</span><br className=" hidden" />{" "}
            <br/>
            <span>The Next in Data</span><br className=" hidden" />{" "}
            <span className="text-gradient">Discovery!</span><br className="sm:block hidden" />{" "}
          </h1>

          <div className="ss:flex hidden md:mr-4 mr-0">
            <Link to='/register'><GetStarted /></Link>
          </div>
        </div>
        <h2>
        </h2>
        <p className={`${styles.paragraph} max-w-[680px] mt-5`}>
          <b style={{ color: 'blue' }}>Search ... </b>Using advanced discovery algorithms developed to identify and catalog each individual autopart into one simple collection.
          <br/>
          <br/>
          <b style={{ color: 'blue' }}>Discover ... </b> Browse full collections of categories and brands from the market leaders: 
          <br/> 
          <b style={{ color: 'red' }}>Autozone, Napa, Advance Auto, O'Reilly Auto and others ...</b>
          <br/> 
          <br/> 
          <b style={{ color: 'blue' }}>ID ... </b>  Each cataloged with: Part Number, Category, Brand, Description, Specifications and more ... 
          <br/> 
          <br/> 
          <Link to='/register'>
            <b style={{ color: 'red' }}>~ Sign up for a membership today ~ </b>   
          </Link>
          <br/> 
          <br/> 

        </p>
      </div>

      <div className={styles.robot}>
        <img src={robot} alt="billing" className="w-[100%] h-[100%] relative z-[5]" />
      </div>

    </section>
  );
};

export default Hero;