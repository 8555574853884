import { useState } from 'react';
import styles from './Auth.module.scss';
import loginImg from '../../assets/login3.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import Card from '../../components/card/Card';
import {
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';
import { auth } from '../../firebase/config';
import { toast } from 'react-toastify';
import Loader from '../../components/loader/Loader';
import { useSelector } from 'react-redux';

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	// const previousURL = useSelector(selectPreviousURL);
	const navigate = useNavigate();

	// const redirectUser = () => {
	// 	if (previousURL.includes('projects')) {
	// 		return navigate('/projects');
	// 	}
	// 	navigate('/home');
	// };

	const loginUser = (e) => {
		e.preventDefault();
		setIsLoading(true);

		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// const user = userCredential.user;
				setIsLoading(false);
				toast.success('Login Successful...');
				navigate('/home');
				// redirectUser();
			})
			.catch((error) => {
				setIsLoading(false);
				toast.error(error.message);
			});
	};

	return (
		<>
			{isLoading && <Loader />}
			<section className={`container ${styles.auth}`}>
				<div className={styles.img}>
					<img src={loginImg} alt='Login' width='600' />
				</div>

				<Card>
					<div className={styles.form}>
						<h2>Login</h2>

						<form onSubmit={loginUser}>
							<input
								type='text'
								placeholder='Email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<input
								type='password'
								placeholder='Password'
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<button type='submit' className='--btn --btn-primary --btn-block'>
								Login
							</button>
							<div className={styles.links}>
								<Link to='/reset' style={{ color: 'orange' }}>
									Reset Password
								</Link>
							</div>
						</form>

						<span className={styles.register}>
							<p style={{ color: 'white' }}>Don't have a Membership ? </p>
							<Link to='/register' style={{ color: 'orange' }}>
								{' '}
								{' .  Register'}
							</Link>
						</span>
					</div>
				</Card>
			</section>
		</>
	);
};

export default Login;
