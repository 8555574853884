import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';
import filterReducer from './slice/filterSlice';
import pidsCardReducer from './slice/pidsCardSlice';
import productReducer from './slice/productSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	product: productReducer,
	filter: filterReducer,
	pidsCard: pidsCardReducer,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
